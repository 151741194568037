<template>
	<div>
		<vs-row>
			<vs-col>
				<leads-data-table />
			</vs-col>
		</vs-row>
	</div>
</template>

<script>
import LeadsNavBar from '../components/pagesComponents/LeadsNavBar.vue'
import LeadsDataTable from '../components/pagesComponents/LeadsDataTable.vue'
import Datepicker from 'vuejs-datepicker';
import EventBus from '../components/eventbus.js'
export default {
	components: {
		Datepicker,
		LeadsNavBar,
		LeadsDataTable,
	},
	data() {
		return {
			searchQuery: ''
		}
	},
	mounted() {
		this.$store.commit('LEADS_MOUNTED')
		this.$store.commit('CHANGE_BDE_NAME', 'all')
	},
	methods: {
		addlead() {
			EventBus.$emit('open-add-lead-popup')
			let popup = document.getElementsByClassName('vs-popup')
			console.log(popup)
		}
	}
}
</script>

<style>
	.headr {
		color: gray;
		font-size: 25px;
		margin-bottom: 2% 
	}
	.gap{
		margin-bottom: 2%
	};
	.inicolor{
		color: gray;
	}
	.cardtext {
	color: gray; 
	font-size: 20px
	};
</style>
